import Vue from "vue";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";

import translationsEn from "../locale/laos";
import translationsDe from "../locale/th";

Vue.use(Vuex);

let state = {
  curLanguage: null,
  languages: [
    {
      short: "laos",
      long: "Laos",
    },
    {
      short: "th",
      long: "Thai",
    },
  ],
};

state.curLanguage = state.languages[0];

const mutations = {
  setLanguage(state, lang) {
    state.curLanguage = lang;
  },
};

const store = new Vuex.Store({
  state,
  mutations,
  modules: {},
});

Vue.use(vuexI18n.plugin, store);

Vue.i18n.add("laos", translationsEn);
Vue.i18n.add("th", translationsDe);

export default store;
